import BN from "bn.js";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import {
  fetchAccountEthBalance,
  fetchCurrentGasPrice,
} from "../Api/blockchain";
import { useWeb3React } from "@web3-react/core";
import { GAS_FOR_MIGRATION } from "../constants";
import { BigNumber } from "@ethersproject/bignumber";

export const GasEstimate: FC = () => {
  const [accountBalance, setAccountBalance] = useState<BN>(new BN(0));
  const [gasEstimate, setGasEstimate] = useState<BN>(new BN(0));

  const { account, chainId } = useWeb3React();

  //const tenToSixteen = new BN(10000000000000000);
  const tenToSixteen = new BN("10000000000000000");
  const tenToFourteen = new BN(100000000000000);

  useEffect(() => {
    async function setStateVariables() {
      const currentGasPrice = await fetchCurrentGasPrice();
      console.log("Gas Price is " + currentGasPrice.toString());
      const gasUsed = process.env.GAS_FOR_MIGRATION || GAS_FOR_MIGRATION;
      const gasCost = new BN(currentGasPrice.toString()).mul(new BN(gasUsed));

      setGasEstimate(gasCost);
      const accountEthBalance = new BN(
        (await fetchAccountEthBalance(account!)).toString()
      );
      console.log("Account Eth balance is " + accountEthBalance);
      setAccountBalance(accountEthBalance);
    }
    setStateVariables();
  }, [account]);

  const hasEnoughGas = accountBalance.gt(gasEstimate);

  const gasCostString = gasEstimate.gt(tenToSixteen)
    ? ".0" + gasEstimate.div(new BN(tenToFourteen))
    : ".00" + gasEstimate.div(new BN(tenToFourteen));

  return (
    <GasLabel enoughGas={hasEnoughGas}>
      Gas Estimate : {gasCostString} Eth
    </GasLabel>
  );
};

interface GasProps {
  enoughGas: boolean;
}

const GasLabel = styled.h2<GasProps>`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.enoughGas ? "rgb(100, 216, 103)" : "red")};
  text-align: center;
  margin: 0;
`;
