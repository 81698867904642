import { AppContext } from "../hooks/context";
import { setTxSubmittedModal } from "../hooks/state";
import { Web3Provider } from "@ethersproject/providers";
import styled, { css } from "styled-components";
import { FC, useContext, useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import {
  DAO_V1_CONTRACT_ABI,
  DAO_V1_MAINNET_ADDRESS,
  GOERLI_NAME,
  MAINNET_CHAIN_ID,
  MAINNET_NAME,
  TOKEN_V1_CONTRACT_ABI,
  TOKEN_V1_MAINNET_ADDRESS,
} from "../constants";
import {
  fetchV1Balance,
  fetchV2Balance,
  v1DaoAllowanceExceedsBalance,
} from "../Api/blockchain";
import BN from "bn.js";
import { ConnectionType } from "../connectors/connections";

interface MigrateProps {
  migrateProvider: Web3Provider | null;
  v1Balance: BN;
  setV1Balance: React.Dispatch<React.SetStateAction<BN>>;
  setV2Balance: React.Dispatch<React.SetStateAction<BN>>;
  connectionType: ConnectionType | null;
}
/*
SEPERATE MIGRATE AND CONNECT BUTTONS INTO DIFFERENT COMPONENTS???
Use hook for balances like useAccountHasMinimumNominationBalance
*/

export const MigrateButton: FC<MigrateProps> = ({
  migrateProvider,
  v1Balance,
  setV1Balance,
  setV2Balance,
  connectionType,
}) => {
  const { dispatch } = useContext(AppContext);
  const { account, chainId } = useWeb3React();
  const [hasApproved, setHasApproved] = useState<boolean>(false);
  const [txSubmitted, setTxSubmitted] = useState<boolean>(false);
  const v1TokenAddress =
    process.env.REACT_APP_TOKEN_V1_CONTRACTADDRESS || TOKEN_V1_MAINNET_ADDRESS;
  const v1DAOAddress =
    process.env.REACT_APP_DAO_V1_CONTRACTADDRESS || DAO_V1_MAINNET_ADDRESS;

  let v1contract = new Contract(
    v1TokenAddress,
    TOKEN_V1_CONTRACT_ABI,
    migrateProvider?.getSigner()
  );

  let v1DAOContract = new Contract(
    v1DAOAddress,
    DAO_V1_CONTRACT_ABI,
    migrateProvider?.getSigner()
  );

  useEffect(() => {
    async function fetchRequirements() {
      await v1DaoAllowanceExceedsBalance(
        account!,
        setHasApproved,
        setV1Balance
      );
    }

    fetchRequirements();
  }, [account, setV1Balance, setV2Balance]);

  const approve = async () => {
    if (connectionType === ConnectionType.COINBASE_WALLET) {
      dispatch(
        setTxSubmittedModal({
          modalTxHash: "",
          txPending: true,
          isMigration: false,
          walletConnection: connectionType,
        })
      );
      setTxSubmitted(true);
    }
    console.log("balance for approval is " + v1Balance);
    const tx = await v1contract.approve(
      v1DAOAddress,
      v1Balance.mul(new BN(2)).toString()
    );
    console.log("tx is " + tx.hash.toString());
    setTxSubmitted(true);
    dispatch(
      setTxSubmittedModal({
        modalTxHash: tx.hash.toString(),
        txPending: true,
        isMigration: false,
        walletConnection: connectionType,
      })
    );
    const receipt = await tx.wait();
    await v1DaoAllowanceExceedsBalance(account!, setHasApproved, setV1Balance);
    setV2Balance(await fetchV2Balance(account!));
    setTxSubmitted(false);
    dispatch(
      setTxSubmittedModal({
        modalTxHash: tx.hash.toString(),
        txPending: false,
        isMigration: false,
        walletConnection: connectionType,
      })
    );
    console.log("transaction is mined " + receipt.toString());
  };

  const migrate = async () => {
    if (connectionType === ConnectionType.COINBASE_WALLET) {
      dispatch(
        setTxSubmittedModal({
          modalTxHash: "",
          txPending: true,
          isMigration: false,
          walletConnection: connectionType,
        })
      );
      setTxSubmitted(true);
    }
    const tx = await v1DAOContract.migrateTokens();
    console.log("tx is " + tx.hash.toString());
    setTxSubmitted(true);
    dispatch(
      setTxSubmittedModal({
        modalTxHash: tx.hash.toString(),
        txPending: true,
        isMigration: true,
        walletConnection: connectionType,
      })
    );
    const receipt = await tx.wait();
    await v1DaoAllowanceExceedsBalance(account!, setHasApproved, setV1Balance);
    setV2Balance(await fetchV2Balance(account!));
    setTxSubmitted(false);
    dispatch(
      setTxSubmittedModal({
        modalTxHash: tx.hash.toString(),
        txPending: false,
        isMigration: true,
        walletConnection: connectionType,
      })
    );
    console.log("transaction is mined " + receipt.toString());
  };

  const desiredChainID = process.env.REACT_APP_CHAIN_ID;

  if (desiredChainID != null && desiredChainID !== chainId?.toString()) {
    const networkName =
      desiredChainID === MAINNET_CHAIN_ID.toString()
        ? MAINNET_NAME
        : GOERLI_NAME;
    return (
      <StyledMigrateButton disabled={true}>
        Change Network to {networkName}
      </StyledMigrateButton>
    );
  } else if (txSubmitted) {
    return (
      <StyledMigrateButton disabled={true}>Tx Submitted</StyledMigrateButton>
    );
  } else if (v1Balance.isZero()) {
    return (
      <StyledMigrateButton disabled={true}>No V1 Apollo</StyledMigrateButton>
    );
  } else if (!hasApproved) {
    return (
      <StyledMigrateButton onClick={approve} valid={true}>
        Approve
      </StyledMigrateButton>
    );
  } else {
    return (
      <>
        <StyledMigrateButton onClick={migrate} valid={true}>
          Migrate
        </StyledMigrateButton>
      </>
    );
  }
};

const StyledMigrateButton = styled.button.attrs(
  (props: { valid: boolean }) => props
)`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: ${(props) => (props.valid ? "#68ff85" : "#d0d5dd")};
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
export const textFieldCSS = css`
  padding: 10px 14px;

  border-width: 1px;
  border-style: solid;
  border-color: #d0d5dd;
  border-radius: 8px;

  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #101828;
  transition: box-shadow 200ms ease, border-color 200ms ease;

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(104, 255, 133, 0.2);
    border-color: #68ff85;
  }
`;
