
import { JsonRpcProvider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import BN from 'bn.js';
import { infuraHTTPEndpoint } from '../helpers/infura';
import { DAO_V1_MAINNET_ADDRESS, TOKEN_V1_CONTRACT_ABI, TOKEN_V1_MAINNET_ADDRESS, TOKEN_V2_CONTRACT_ABI, TOKEN_V2_MAINNET_ADDRESS } from '../constants';
import { BigNumber } from "@ethersproject/bignumber";


const provider = new JsonRpcProvider(infuraHTTPEndpoint());

const v1TokenAddress =
    process.env.REACT_APP_TOKEN_V1_CONTRACTADDRESS || TOKEN_V1_MAINNET_ADDRESS;
const v1DAOAddress =
    process.env.REACT_APP_DAO_V1_CONTRACTADDRESS || DAO_V1_MAINNET_ADDRESS;
const v2TokenAddress = process.env.REACT_APP_TOKEN_V2_CONTRACTADDRESS || TOKEN_V2_MAINNET_ADDRESS;

const v1TokenContract = new Contract(v1TokenAddress,TOKEN_V1_CONTRACT_ABI, provider);
const v2TokenContract = new Contract(v2TokenAddress,TOKEN_V2_CONTRACT_ABI, provider);


export const fetchV1DAOAllowance = async( account: string): Promise<BN> => {
    const response = await v1TokenContract.allowance(account,v1DAOAddress);
    console.log("v1 DAO allowance is " + response);
    const allowance = new BN(response.toString())
    return allowance;
};

export const fetchV1Balance = async(account: string) : Promise<BN> => {
    const response = await v1TokenContract.balanceOf(account);
    console.log("v1 balance is " + response);
    const balance = new BN(response.toString());
    return balance;
};
export const fetchV2Balance = async(account:string) : Promise<BN> => {
    const response = await v2TokenContract.balanceOf(account);
    console.log("v2 balance is " + response);
    const balance = new BN(response.toString());
    return balance;
};

export const v1DaoAllowanceExceedsBalance = async( account : string, setHasApproved:React.Dispatch<React.SetStateAction<boolean>> | null, setV1Balance:React.Dispatch<React.SetStateAction<BN>> | null) : Promise<boolean> => {
    const balance = await fetchV1Balance(account);
    if(setV1Balance != null){
        setV1Balance(balance);
    }
    const allowance = await fetchV1DAOAllowance(account);
    const allowanceGreater = allowance.gte(balance)
    if(setHasApproved != null){
        setHasApproved(allowanceGreater);
    }
    console.log("allowance is greater : " + allowanceGreater);
    return allowanceGreater;

};
export const fetchCurrentGasPrice = async() : Promise<BigNumber> => {
    const response = await provider.getGasPrice();
    return response;
};

export const fetchAccountEthBalance = async (account : string) : Promise<BigNumber> => {
    const response = await provider.getBalance(account);
    return response;
}