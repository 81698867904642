import React, { FC, useEffect, useState } from "react";
import GlobalStyle from "../shared/ui/GlobalStyles";

import { DesktopOrMobileNavBar } from "../navbar/DesktopOrMobileNavBar";
import { ConnectWalletModal } from "../components/wallet/ConnectWalletModal";
import { AppContextProvider } from "../hooks/context";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import { Maintenance } from "../components/Maintenance";
import { TxSubmittedModal } from "../components/TxSubmittedModal";
import ReactGA from "react-ga4";
import { Widget } from "../components/Widget/Widget";
import { ConnectionType } from "../connectors/connections";

const App: FC = () => {
  const { provider, isActive } = useWeb3React();
  const [connectionType, setConnectionType] = useState<ConnectionType | null>(
    null
  );
  const providerForWidget = isActive && provider != null ? provider : null;
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
    console.log("sent analytics");
  });
  if (process.env.REACT_APP_PUBLIC_MAINTENANCE === "true") {
    return <Maintenance />;
  } else {
    return (
      <Wrapper>
        <GlobalStyle />
        <AppContextProvider>
          <DesktopOrMobileNavBar />

          <ConnectWalletModal
            connectionType={connectionType}
            setConnectionType={setConnectionType}
          />
          <TxSubmittedModal />
          <Widget
            migrateProvider={providerForWidget}
            connectionType={connectionType}
          />
          <Footer>
            <div>
              <a
                href="https://www.apolloftw.com/"
                target="_blank"
                rel="noreferrer"
              >
                ApolloFTW.com
              </a>{" "}
              •
              <a
                href="https://www.apolloftw.com/apollo-migration-terms-of-service/"
                target="_blank"
                rel="noreferrer"
              >
                Terms Of Service
              </a>
            </div>
          </Footer>
        </AppContextProvider>
      </Wrapper>
    );
  }
};

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem;

  a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    padding: 0.75rem;
    text-decoration: none;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;
