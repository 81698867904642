import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { FC, useEffect } from "react";
import { effectiveMobileMaxWidth } from "../shared/ui/Constants";
import BN from "bn.js";
import { GOERLI_CHAIN_ID, TEN_RAISED_18, TEN_RAISED_9 } from "../constants";
import { fetchV1Balance, fetchV2Balance } from "../Api/blockchain";
import { useWeb3React } from "@web3-react/core";

interface BalanceProps {
  migrateProvider: Web3Provider | null;
  v1Balance: BN;
  setV1Balance: React.Dispatch<React.SetStateAction<BN>>;
  v2Balance: BN;
  setV2Balance: React.Dispatch<React.SetStateAction<BN>>;
  etherscanv1Balance: BN;
  setEtherscanV1Balance: React.Dispatch<React.SetStateAction<BN>>;
}

export const BalancesCard: FC<BalanceProps> = ({
  migrateProvider,
  v1Balance,
  setV1Balance,
  v2Balance,
  setV2Balance,
  etherscanv1Balance,
  setEtherscanV1Balance,
}) => {
  const { account } = useWeb3React();

  useEffect(() => {
    async function fetchBalances() {
      setV1Balance(await fetchV1Balance(account!));
      setV2Balance(await fetchV2Balance(account!));
      setEtherscanV1Balance(new BN(0));
    }

    fetchBalances();
  }, [account, setV1Balance, setV2Balance, setEtherscanV1Balance]);

  const formattedV1Balance = Intl.NumberFormat("en-US").format(
    v1Balance.div(new BN(TEN_RAISED_9)).toNumber()
  );
  const formattedV2Balance = Intl.NumberFormat("en-US").format(
    v2Balance.div(new BN(TEN_RAISED_18)).toNumber()
  );
  const formattedEtherscanV1Balance = Intl.NumberFormat("en-US").format(
    etherscanv1Balance.div(new BN(TEN_RAISED_9)).toNumber()
  );
  const migrationOpen = process.env.REACT_APP_MIGRATION_OPEN;
  const secondLabel =
    migrationOpen === "true" ? "V2 Balance" : "Etherscan Balance";
  const secondBalance =
    migrationOpen === "true"
      ? formattedV2Balance
      : etherscanv1Balance.isZero()
      ? v1Balance.isZero()
        ? formattedEtherscanV1Balance
        : "Not Updated"
      : formattedEtherscanV1Balance;
  if (migrateProvider == null) {
    return <></>;
  } else {
    return (
      <CardContainer>
        <LabelContainer>
          <Label1>V1 Balance : {formattedV1Balance}</Label1>
          <Label2>
            <Href
              target="_blank"
              rel="noopener noreferrer"
              href={getURL(
                process.env.REACT_APP_CHAIN_ID,
                migrationOpen,
                account
              )}
            >
              {secondLabel} : {secondBalance}
            </Href>
          </Label2>
        </LabelContainer>
      </CardContainer>
    );
  }
};

const getURL = (
  chainId: string | undefined,
  migrationOpen: string | undefined,
  account: string | undefined
): string => {
  const testChain = chainId === GOERLI_CHAIN_ID.toString() ? "goerli." : "";
  const token2Address =
    migrationOpen === "true"
      ? process.env.REACT_APP_TOKEN_V2_CONTRACTADDRESS
      : process.env.REACT_APP_TOKEN_V1_CONTRACTADDRESS;
  return (
    "https://" +
    testChain +
    "etherscan.io/token/" +
    token2Address +
    "?a=" +
    account
  );
};

const CardContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 93%;
  max-width: 15em;

  padding: 20px 0px;
  background-color: #ffffff;
  border-radius: 32px;
  border: 1px solid #a1a9bf;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    align-items: center;
  }
`;

const Label1 = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  text-align: center;
  margin: 0;
`;

const Label2 = styled.h2`
  font-weight: 400;
  font-size: 14px;
  color: #72796f;
  text-align: center;
  margin: 0;
`;

const Href = styled.a`
  color: rgb(100, 216, 103);
  background-color: transparent;
  text-decoration: underline;

  &:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  }
`;
