import styled from "styled-components";
import { FC } from "react";
import apolloLogo from "../../styles/svg/apollo.svg";
import BN from "bn.js";
import {
  CardContainer,
  LabelContainer,
  LeadingElementsContainer,
  NameLabel,
  Image,
  WidgetSubLabel,
} from "./Widget";
import { fetchV1Balance } from "../../Api/blockchain";
import { useWeb3React } from "@web3-react/core";
import {
  TOKEN_V1_ETHERSCAN_PREFIX,
  TOKEN_V1_MAINNET_ADDRESS,
} from "../../constants";
import axios from "axios";

interface EtherscanWWidgetProps {
  v1Balance: BN;
  setV1Balance: React.Dispatch<React.SetStateAction<BN>>;
  setEtherscanV1Balance: React.Dispatch<React.SetStateAction<BN>>;
}

export const EtherscanWidget: FC<EtherscanWWidgetProps> = ({
  setEtherscanV1Balance,
}) => {
  const { account } = useWeb3React();
  const url = TOKEN_V1_ETHERSCAN_PREFIX + "?a=" + account;

  const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;

  const openInNewTab = () => {
    window.open(url, "_blank", "noreferrer");
  };
  const v1TokenAddress = TOKEN_V1_MAINNET_ADDRESS;

  const apiUrl =
    "https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=" +
    v1TokenAddress +
    "&address=" +
    account! +
    "&tag=latest&apikey=" +
    apiKey;

  const fetchEtherscanBalance = async () => {
    const tokenData = await axios.get(apiUrl);
    const tokenResult = tokenData.data.result;
    console.log("Etherscan balance is " + tokenResult);
    setEtherscanV1Balance(new BN(tokenResult));
    openInNewTab();
  };

  return (
    <CardContainer>
      <LeadingElementsContainer>
        <Image src={apolloLogo} alt="Apollo Crypto DAO logo " />
        <LabelContainer>
          <NameLabel>Apollo Etherscan Update</NameLabel>
          <WidgetSubLabel>Click to Update Etherscan Balance</WidgetSubLabel>
          <WidgetSubLabel>Migration Opens Week of 3/11/24</WidgetSubLabel>
        </LabelContainer>
      </LeadingElementsContainer>
      <StyledEtherscanButton
        onClick={() => fetchEtherscanBalance()}
        valid={true}
      >
        Update
      </StyledEtherscanButton>
    </CardContainer>
  );
};

const StyledEtherscanButton = styled.button.attrs(
  (props: { valid: boolean }) => props
)`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: ${(props) => (props.valid ? "#68ff85" : "#d0d5dd")};
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
