import { FC, useContext } from "react";
import { Modal } from "./Modal";
import styled from "styled-components";
import { AppContext } from "../hooks/context";
import { setTxSubmittedModal } from "../hooks/state";
import { useWeb3React } from "@web3-react/core";
import {
  GOERLI_CHAIN_ID,
  TX_APPROVAL_CONFIRMED_TEXT,
  TX_APPROVAL_CONFIRMED_TITLE_TEXT,
  TX_CONFIRMED_BUTTON_TEXT,
  TX_MIGRATION_CONFIRMED_TEXT,
  TX_MIGRATION_CONFIRMED_TITLE_TEXT,
  TX_SUBMITTED_BUTTON_TEXT,
  TX_SUBMITTED_CBWALLET_TEXT,
  TX_SUBMITTED_TEXT,
  TX_SUBMITTED_TITLE_TEXT,
} from "../constants";
import { ConnectionType } from "../connectors/connections";

export const TxSubmittedModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { chainId, account } = useWeb3React();
  const isShown = state.txSubmittedModal != null;
  const shouldClose = () => dispatch(setTxSubmittedModal(null));

  let helpText = "";
  let buttonText = "";
  let titleText = "";

  if (state.txSubmittedModal?.txPending) {
    //Transaction Pending
    if (
      state.txSubmittedModal?.walletConnection ===
      ConnectionType.COINBASE_WALLET
    ) {
      //Transaction submitted with CB Wallet
      helpText = TX_SUBMITTED_CBWALLET_TEXT;
      buttonText = "";
      titleText = TX_SUBMITTED_TITLE_TEXT;
    } else {
      //transaction submitted with any other wallet
      //Transaction submitted
      helpText = TX_SUBMITTED_TEXT;
      buttonText = TX_SUBMITTED_BUTTON_TEXT;
      titleText = TX_SUBMITTED_TITLE_TEXT;
    }
  } else {
    //Transaction confirmed
    if (state.txSubmittedModal?.isMigration) {
      //Completed Migration
      helpText = TX_MIGRATION_CONFIRMED_TEXT;
      buttonText = TX_CONFIRMED_BUTTON_TEXT;
      titleText = TX_MIGRATION_CONFIRMED_TITLE_TEXT;
    } else {
      //Completed Approval
      helpText = TX_APPROVAL_CONFIRMED_TEXT;
      buttonText = TX_CONFIRMED_BUTTON_TEXT;
      titleText = TX_APPROVAL_CONFIRMED_TITLE_TEXT;
    }
  }
  let URL = getURL(
    chainId?.toString(),
    state.txSubmittedModal?.txPending,
    state.txSubmittedModal?.modalTxHash,
    account
  );

  return (
    <Modal
      contentWidth="520px"
      isShown={isShown}
      shouldClose={shouldClose}
      title={titleText}
    >
      <ModalContent>
        <HelpText>{helpText}</HelpText>
        {state.txSubmittedModal?.walletConnection !=
          ConnectionType.COINBASE_WALLET && (
          <StyledActionButton
            onClick={() => window.open(URL, "_blank", "noreferrer")}
          >
            {buttonText}
          </StyledActionButton>
        )}
      </ModalContent>
    </Modal>
  );
};

const getURL = (
  chainId: string | undefined,
  waitingOnFonfirmation: boolean | undefined,
  txHash: string | undefined,
  account: string | undefined
): string => {
  const testChain = chainId === GOERLI_CHAIN_ID.toString() ? "goerli." : "";
  return "https://" + testChain + "etherscan.io/tx/" + txHash;
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 1rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const HelpText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  color: #79809d;
  margin: 0 0 1rem;
`;

const StyledActionButton = styled.button`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: #68ff85;
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
