import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { FC, useState } from "react";
import { effectiveMobileMaxWidth } from "../../shared/ui/Constants";
import BN from "bn.js";
import { BalancesCard } from "../BalancesCard";
import { MigrationWidget } from "./MigrationWidget";
import { ConnectWidget } from "./ConnectWidget";
import { EtherscanWidget } from "./EtherscanWidget";
import { ConnectionType } from "../../connectors/connections";

interface MigrateProps {
  migrateProvider: Web3Provider | null;
  connectionType: ConnectionType | null;
}

export const Widget: FC<MigrateProps> = ({
  migrateProvider,
  connectionType,
}) => {
  const [v1Balance, setV1Balance] = useState<BN>(new BN(0));
  const [v2Balance, setV2Balance] = useState<BN>(new BN(0));
  const [etherscanV1Balance, setEtherscanV1Balance] = useState<BN>(new BN(0));

  if (migrateProvider != null) {
    if (process.env.REACT_APP_MIGRATION_OPEN === "true") {
      return (
        <WidgetContainer>
          <MigrationWidget
            migrateProvider={migrateProvider}
            v1Balance={v1Balance}
            setV1Balance={setV1Balance}
            v2Balance={v2Balance}
            setV2Balance={setV2Balance}
            connectionType={connectionType}
          />
          <BalancesCard
            migrateProvider={migrateProvider}
            v1Balance={v1Balance}
            setV1Balance={setV1Balance}
            v2Balance={v2Balance}
            setV2Balance={setV2Balance}
            etherscanv1Balance={etherscanV1Balance}
            setEtherscanV1Balance={setEtherscanV1Balance}
          />
        </WidgetContainer>
      );
    } else {
      return (
        <WidgetContainer>
          <EtherscanWidget
            v1Balance={v1Balance}
            setV1Balance={setV1Balance}
            setEtherscanV1Balance={setEtherscanV1Balance}
          />
          <BalancesCard
            migrateProvider={migrateProvider}
            v1Balance={v1Balance}
            setV1Balance={setV1Balance}
            v2Balance={v2Balance}
            setV2Balance={setV2Balance}
            etherscanv1Balance={etherscanV1Balance}
            setEtherscanV1Balance={setEtherscanV1Balance}
          />
        </WidgetContainer>
      );
    }
  } else {
    return (
      <WidgetContainer>
        <ConnectWidget />
      </WidgetContainer>
    );
  }
};

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 93%;
  max-width: 29em;

  padding: 32px 0px;
  background-color: #ffffff;
  border-radius: 32px;
  border: 1px solid #a1a9bf;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const WidgetContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 4rem 0;
`;

export const LeadingElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    align-items: center;
  }
`;

export const NameLabel = styled.h1`
  font-weight: 400;
  font-size: 22px;
  color: #002106;
  margin: 0;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const Image = styled.img`
  width: 88px;
  height: 88px;
  object-fit: cover;
  border-radius: 50%;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 88px;
    height: 88px;
  }
`;

export const WidgetSubLabel = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  text-align: center;
  margin: 0;
`;
