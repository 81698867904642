import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2} from "@web3-react/walletconnect-v2";

import { Connection, ConnectionType, onConnectionError } from "./connections";
import { CHAIN_TO_URL_MAP, WALLET_CONNECT_ID } from "../constants";

export function buildWalletConnectConnector() {
  const ID = process.env.REACT_APP_WALLETCONNECT_ID || WALLET_CONNECT_ID;
  const [web3WalletConnect, web3WalletConnectHooks] =
    initializeConnector<WalletConnectV2>(
      (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: ID,
          chains: [1],
          showQrModal: true,
        },
      })
    );
  const walletConnectConnection: Connection = {
    connector: web3WalletConnect,
    hooks: web3WalletConnectHooks,
    type: ConnectionType.WALLET_CONNECT,
  };
  return walletConnectConnection;
}
